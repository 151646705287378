var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"home-c"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("举报须知！")]),(_vm.isPC)?_c('div',{staticClass:"detail"},[_c('div',{staticClass:"detail1"},[_vm._v(" 如您知悉任何玖沐集团、各子/分公司员工在商业活动中存在以下行为， ")]),_c('div',{staticClass:"detail2"},[_vm._v("可通过本界面进行举报。")])]):_c('div',{staticClass:"detail"},[_c('div',{staticClass:"detail1"},[_vm._v(" 如您知悉任何玖沐集团、各子/分公司员工在商业活动中存在以下行为，可通过本界面进行举报。 ")])])]),_c('div',{staticClass:"contentMain"},[_c('div',{staticClass:"content"},[(_vm.isPC)?_c('div',{staticClass:"image"}):_vm._e(),_c('ul',{staticClass:"right"},[_vm._m(0),_vm._m(1),_c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("03")]),_c('div',{staticClass:"text",class:_vm.isPC ? 'pcthree' : 'mobilethree'},[_vm._v(" 职务侵占（将集团财务及资产非法占为己有）、不正当利益输送行为 ")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])]),_vm._m(7),_c('div',{staticClass:"confirm"},[_c('el-checkbox',{staticClass:"sure",attrs:{"size":"medium"},on:{"change":_vm.confirmEvent},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}}),_vm._v("确认本人已完成"),_c('span',{staticStyle:{"color":"#3376ff","cursor":"pointer"}},[_vm._v("《举报须知》")])],1),_c('div',{staticClass:"goReport"},[(_vm.isPC)?_c('div',{class:_vm.confirm ? 'report' : 'nomal',on:{"click":function($event){return _vm.goReportEvent()}}},[_vm._v(" 前往举报 ")]):_c('div',{class:_vm.confirm ? 'report' : 'nomal',on:{"click":function($event){return _vm.goReportEvent()}}},[_vm._v(" 违纪举报 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("01")]),_c('div',{staticClass:"text"},[_vm._v("行贿、受贿、索贿行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("02")]),_c('div',{staticClass:"text"},[_vm._v("贪污腐败、权钱交易行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("04")]),_c('div',{staticClass:"text"},[_vm._v("泄露集团机密行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("05")]),_c('div',{staticClass:"text"},[_vm._v("从事与集团有商业竞争或利益冲突的行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("06")]),_c('div',{staticClass:"text"},[_vm._v("利用职务之便私收罚款行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("07")]),_c('div',{staticClass:"text"},[_vm._v("诈骗、合同诈骗的行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('div',{staticClass:"cricle"},[_vm._v("08")]),_c('div',{staticClass:"text"},[_vm._v("其他一切违法行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_vm._v(" 对您的身份和举报信息我们将"),_c('span',{staticStyle:{"color":"#6899fe","cursor":"pointer"}},[_vm._v("严格保密")]),_vm._v(" ，财务部（审计）、法务部将展开联合调查。感谢您对玖沐集团监察反舞弊工作的支持！ ")])
}]

export { render, staticRenderFns }