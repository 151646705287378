<template>
  <div class="home">
    <div class="home-c">
      <div class="header">
        <!-- <img style="height: 100%" src="~@/assets/img/bgimg2.png" alt="" /> -->
        <div class="title">举报须知！</div>
        <div class="detail" v-if="isPC">
          <div class="detail1">
            如您知悉任何玖沐集团、各子/分公司员工在商业活动中存在以下行为，
          </div>
          <div class="detail2">可通过本界面进行举报。</div>
        </div>
        <div class="detail" v-else>
          <div class="detail1">
            如您知悉任何玖沐集团、各子/分公司员工在商业活动中存在以下行为，可通过本界面进行举报。
          </div>
          <!-- <div class="detail2"></div> -->
        </div>
      </div>
      <div class="contentMain">
        <div class="content">
          <div class="image" v-if="isPC"></div>
          <ul class="right">
            <li class="item">
              <div class="cricle">01</div>
              <div class="text">行贿、受贿、索贿行为</div>
            </li>
            <li class="item">
              <div class="cricle">02</div>
              <div class="text">贪污腐败、权钱交易行为</div>
            </li>
            <li class="item">
              <div class="cricle">03</div>
              <div class="text" :class="isPC ? 'pcthree' : 'mobilethree'">
                职务侵占（将集团财务及资产非法占为己有）、不正当利益输送行为
              </div>
            </li>
            <li class="item">
              <div class="cricle">04</div>
              <div class="text">泄露集团机密行为</div>
            </li>
            <li class="item">
              <div class="cricle">05</div>
              <div class="text">从事与集团有商业竞争或利益冲突的行为</div>
            </li>
            <li class="item">
              <div class="cricle">06</div>
              <div class="text">利用职务之便私收罚款行为</div>
            </li>
            <li class="item">
              <div class="cricle">07</div>
              <div class="text">诈骗、合同诈骗的行为</div>
            </li>
            <li class="item">
              <div class="cricle">08</div>
              <div class="text">其他一切违法行为</div>
            </li>
          </ul>
        </div>
        <div class="tip">
          对您的身份和举报信息我们将<span
            style="color: #6899fe; cursor: pointer"
            >严格保密</span
          >
          ，财务部（审计）、法务部将展开联合调查。感谢您对玖沐集团监察反舞弊工作的支持！
        </div>
        <div class="confirm">
          <el-checkbox
            class="sure"
            size="medium"
            @change="confirmEvent"
            v-model="confirm"
          ></el-checkbox
          >确认本人已完成<span style="color: #3376ff; cursor: pointer"
            >《举报须知》</span
          >
        </div>
        <div class="goReport">
          <div
            v-if="isPC"
            :class="confirm ? 'report' : 'nomal'"
            @click="goReportEvent()"
          >
            前往举报
          </div>
          <div
            v-else
            :class="confirm ? 'report' : 'nomal'"
            @click="goReportEvent()"
          >
            违纪举报
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      confirm: false,
      screenWidth: 0,
      isPC: this.isPCNot(),
    };
  },
  computed: {},
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 750) {
          this.isPC = false;
        } else {
          this.isPC = true;
        }
        console.log("this.isPC", this.isPC);
      },
    },
  },
  created() {},
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  methods: {
    isPCNot() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      console.log("userAgentInfo", userAgentInfo);
      return flag;
    },
    confirmEvent(value) {
      console.log("确认勾选", value);
      this.confirm = value;
      this.$store.state.confirm = value;
      localStorage.setItem("confirm", this.confirm);
      // window.sessionStorage.setItem;
      console.log("this.$store.state.confirm", this.$store.state.confirm);
    },
    goReportEvent() {
      // this.$router.push({
      //   path: '/report',
      //   query: { kind: this.kind, types: item.name }
      // });
      if (this.confirm) {
        this.$router.push("/report");
        // this.$router.push({
        //   path: "/report",
        //   query: { confirm: this.confirm ? 1 : 0 },
        // });
      } else {
        this.$message({
          message: "请勾选确认举报须知",
          type: "warning",
        });
      }
    },
    // goback() {
    //   this.$router.go(-1);
    // }
  },
};
</script>

<style scoped lang="scss">
@import "./scss/home.scss";
</style>
